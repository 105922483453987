import { ColDefinitionDetails } from '../../components';
import { GetColab } from '../../modules/Colab/types';
import { DetailsSection, DetailsSectionHeading } from '../shared/utils';

type ColabRecordProps = { colabEntity?: GetColab };

function ColabDetailsOverview({ colabEntity }: ColabRecordProps) {
  function capitalizeWords(labelName: string) {
    const spacedString = labelName.replace(/([A-Z])/g, ' $1');
    return spacedString
      .split(' ')
      .map(word => (word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''))
      .join(' ');
  }

  if (colabEntity) {
    const labelArr = Object.keys(colabEntity);
    const valueArr = Object.values(colabEntity);

    return (
      <DetailsSection>
        <DetailsSectionHeading>Details</DetailsSectionHeading>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {labelArr.map((labelName, index) => {
            return (
              <ColDefinitionDetails key={index} label={capitalizeWords(labelName)}>
                {valueArr[index]}
              </ColDefinitionDetails>
            );
          })}
        </dl>
      </DetailsSection>
    );
  } else {
    return <div className="text-center text-2xl m-8 text-gray-500">No Record Found</div>;
  }
}

export default ColabDetailsOverview;
