import dayjs from 'dayjs';
import { sentenceCase } from 'change-case';

import { Badge } from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { Booking, Market } from '../../services/bookings/types';
import { CurrencyMap } from '../Bookings/types';
import { ComponentWithChildren } from '../../types';
import { sharedConfig } from '../../config';
import { Availability } from '../../services/availabilities/types';
import { generateFormattedBookingDetails } from '../Bookings/utils';
import { generateFormattedAvailabilityDetails } from '../Availabilities/utils';

export const requestDateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const genericDateFormat = 'HH:mm, MMMM DD YYYY';
const dateFormat = 'MMMM DD YYYY';
const timeFormat = 'HH:mm';

export const formatDate = (dateTime: string | null | undefined) =>
  (dateTime && dayjs(dateTime).format(genericDateFormat)) || '';

export const formatDateOnly = (dateTime: string | null | undefined) =>
  (dateTime && dayjs(dateTime).format(dateFormat)) || '';

export const formatTime = (dateTime: string | null | undefined) =>
  (dateTime && dayjs(dateTime).format(timeFormat)) || '';

export function getMarketName(marketShorthand: string): string {
  const values = Object.values(Market) as string[];
  const keys = Object.keys(Market) as string[];
  const index = values.indexOf(marketShorthand);

  return keys[index];
}

// TODO Refactor from here
export const DetailsSection = ({ children }: ComponentWithChildren) => <section className="py-7">{children}</section>;

export const DetailsSectionHeading = ({ children }: ComponentWithChildren) => (
  <h3 className="font-medium text-xl mb-7">{children}</h3>
);

export const currencies: CurrencyMap = {
  EUR: {
    name: 'Euro',
    symbol: 'euro',
    countryCode: [
      Market.Belgium,
      Market.France,
      Market.Germany,
      Market.Global,
      Market.Italy,
      Market.Netherlands,
      Market.Spain,
    ],
  },
  GBP: {
    name: 'Pound Sterling',
    symbol: 'currency_pound',
    countryCode: [],
  },
  SEK: {
    name: 'Swedish Krona',
    countryCode: [Market.Sweden],
  },
  NOK: {
    name: 'Norwegian Krona',
    countryCode: [],
  },
};

export function getCurrencyByMarket(market: string) {
  const currency = Object.entries(currencies).find(e => e[1].countryCode.includes(market));
  return (currency && currency[0]) || 'EUR';
}

type EquipmentListProps = {
  data: string[];
};

export function EquipmentList({ data }: EquipmentListProps) {
  return (
    <div>
      {data.map((value, index) => (
        <span className="mr-1.5" key={index}>
          <Badge text={sentenceCase(value)} color="green" />
        </span>
      ))}
    </div>
  );
}

export function redirectToDynamicsURL(customerID: string) {
  const baseUrl = sharedConfig.dynamicsUrl;
  if (baseUrl) {
    const redirectUrl = baseUrl.replace('SEARCH_TERM', customerID);
    window.open(redirectUrl);
  } else {
    throw new Error('Error redirecting to Dynamics.');
  }
}

type BookingLocation = [string, string, string];

/**
 * Constructs formatted booking location information.
 * @returns An array of 3 strings: [street, city, country]
 */
export function getBookingLocation(booking: Booking | Availability): BookingLocation {
  const address = booking.location.address;

  if (!address) {
    return ['No address information available', '', ''];
  }

  const route = address.find(({ types }) => types.includes('route'))?.longName;
  const streetNumber = address.find(({ types }) => types.includes('street_number'))?.longName;
  const postalCode = address.find(({ types }) => types.includes('postal_code'))?.longName;
  const postalTown = address.find(({ types }) => types.includes('postal_town'))?.longName;
  const locality = address.find(({ types }) => types.includes('locality'))?.longName;
  const country = address.find(({ types }) => types.includes('country'))?.longName || '';

  const street = `${route || ''} ${streetNumber ? `${streetNumber}, ` : ''}`;
  const city = `${postalCode || ''} ${postalTown || locality || ''}`;

  return [street, city, country];
}

type FormatData = Booking | Availability;
export type FormatFunction = (formatType: FormatData) => string;

function isBooking(subject: FormatData): subject is Booking {
  return (subject as Booking).borrower !== undefined;
}

export function generateFormattedDetails(subject: Availability | Booking) {
  return isBooking(subject) ? generateFormattedBookingDetails(subject) : generateFormattedAvailabilityDetails(subject);
}

export async function copyFormattedDetailsToClipboard(data: Booking | Availability, onError: GenericFunction) {
  try {
    const value = generateFormattedDetails(data);

    await navigator.clipboard.writeText(value);
  } catch (error) {
    onError();
  }
}

export const durations = [
  { title: '0-6 hours', durationKey: 'FEE_DURATION_0_H_TO_6_H' },
  { title: '6-24 hours', durationKey: 'FEE_DURATION_6_H_TO_24_H' },
  { title: '24 hours - 5 days', durationKey: 'FEE_DURATION_24_H_TO_5_DAYS' },
  { title: '5-10 days', durationKey: 'FEE_DURATION_5_DAYS_TO_10_DAYS' },
  { title: '10-28 days', durationKey: 'FEE_DURATION_10_DAYS_TO_28_DAYS' },
];
