import { serviceConfig } from '../../config';
import { axiosInstance } from '../../services/shared';
import { AxiosResponse } from 'axios';
import { ColabQuery, ColabRecords, GetColab } from './types';

async function getColabs(query: ColabQuery) {
  const url = `${serviceConfig.endpoints.legacy.root}/${serviceConfig.endpoints.legacy.colab}`;
  let records;

  try {
    const response: AxiosResponse<ColabRecords> = await axiosInstance.get(url, { params: query });
    records = response?.data?.entities;
    records.sort((a: GetColab, b: GetColab) => b.timestamp.localeCompare(a.timestamp));
    return response.data;
  } catch (error) {
    console.error('Error fetching colab records:', error);
    throw error;
  }
}

export { getColabs };
